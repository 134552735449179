import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import styled from "styled-components"

export default function AccordionBlock(props) {
  const { items, heading, blockId } = props

  const [defaultKey] = useState(0)
  return (
    <Accordions id={blockId} className="accordion-block tpl-block pt-0 pb-6">
      <div className="container lg:px-2 px-4 mx-auto">
        {heading ? <h2 className="mb-4">{heading}</h2> : null}
        {items?.length && (
          <Accordion defaultActiveKey={defaultKey} flush>
            {items.map(({ content, title }, index) => (
              <Accordion.Item eventKey={index} key={index} className={index}>
                <Accordion.Header>
                  {title} <i className="icon-down"></i>
                </Accordion.Header>
                <Accordion.Body dangerouslySetInnerHTML={{ __html: content }} />
              </Accordion.Item>
            ))}
          </Accordion>
        )}
      </div>
    </Accordions>
  )
}

const Accordions = styled.section`
  .collapse {
    visibility: visible;
  }
  .collapse:not(.show) {
    display: none;
  }
  .collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  .accordion-header {
    margin: 0;
  }
  .accordion-button {
    font-size: 2rem;
    padding: 1rem 3rem 1.5rem 0;
    display: block;
    width: 100%;
    text-align: left;
    font-weight: bold;
    position: relative;
    display: block;
    border: none;
    border-bottom: 2px solid #000000;
    background-color: transparent;
    &:not(.collapsed) {
      background-color: transparent;
    }
    &[aria-expanded="true"] {
      .icon-down {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    .icon-down {
      transition: all 0.2s ease;
      font-size: 1.6rem;
      color: #333333;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
  }
  .accordion-body {
    padding: 3rem 0;
    *:last-child {
      margin-bottom: 0;
    }
    ul,
    ol {
      margin-left: 1rem;
      list-style: inside;
    }
    margin: 0;
  }

  .accordion-item {
    background: transparent;
    border-radius: 0 !important;
    &:last-child {
      .accordion-body {
        padding-bottom: 0;
      }
    }
  }
`
