import React from "react"
import BlockSection from "../../components/common/block"
import VideoBox from "../../components/common/video-box"

const VideoBlock = ({ videoId, backgroundImage }) => {
  return (
    <BlockSection className={`video-block tpl-block pb-6`}>
      <div className="container lg:px-2 px-4 mx-auto">
        <VideoBox video={videoId} imageThumb={backgroundImage}></VideoBox>
      </div>
    </BlockSection>
  )
}
export default VideoBlock
